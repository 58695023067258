import React, { useEffect, useState } from 'react';
import { Typography , Divider} from '@mui/material';
import axios from 'axios';

function Leaderboard() {
    const [leaderboardData, setLeaderboardData] = useState('...loading...');

    const getLeaderboardData = async () => {
        try {
            let resp = await axios.get('https://xi13wdmr6f.execute-api.us-east-1.amazonaws.com/default/getPicks');
            // Assuming the response contains the data in a property named 'data'
            //convert to int 
            let c = parseInt(resp.data);
            c= c+13
            setLeaderboardData(c);
        } catch (error) {
            console.error("Error fetching leaderboard data:", error);
        }
    }

    useEffect(() => {
        getLeaderboardData();
    }, []); // Empty dependency array ensures the API call is made only once when the component mounts

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '240px' }}>
            <div>
                <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}>
                    Leaderboard coming on friday morning
                </Typography>
                <Divider sx={{ width: '100%', marginTop: 2, marginBottom: 2 }} />
                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                    Total current entries : {leaderboardData}
                </Typography>
            </div>
        </div>
    )
}

export default Leaderboard;
