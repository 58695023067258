// src/Rules.js
import React from 'react';
import { Typography, Box, Divider } from '@mui/material';

function Rules() {
    // Select 6 picks and enter for a chance to win up to $100!

    // Winning Numbers:
    // 4 Correct Picks: $10
    // 5 Correct Picks: $25
    // 6 Correct Picks: $100

    // Player must be active in game to participate in selection. No purchase necessary, must be 18 years or older to participate.
    return (

        <Box sx={{
            marginTop: 2,
            marginLeft: 2,
        }}>
            <Divider sx={{ width: '100%', marginTop: 2, marginBottom: 2 }} />
            <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                Rules to enter:
            </Typography>
            <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                Winning Numbers:
            </Typography>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                4 Correct Picks: $10
            </Typography>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                5 Correct Picks: $25
            </Typography>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                6 Correct Picks: $100
            </Typography>
            <Divider sx={{ width: '100%', marginTop: 2, marginBottom: 2 }} />

            <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                Player must be active in game to participate in selection. No purchase necessary, must be 18 years or older to participate.
            </Typography>
            <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                If multiplie participants have the same number of correct picks, the prize will be split evenly.
            </Typography>
            <Divider sx={{ width: '100%', marginTop: 2, marginBottom: 2 }} />

            <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                Winner will be paid out via venmo 2 days after the game.
            </Typography>

        </Box>


    )
}

export default Rules;
