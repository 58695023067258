import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { AppBar, Tabs, Tab, Toolbar, Typography } from '@mui/material';
import Leaderboard from './Leaderboard';
import Picks from './Picks';
import Rules from './Rules';

function App() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <Router>
      <div>
        <AppBar position="static" sx={{width:'100%'}}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              No Risk Pick'em
            </Typography>
            <Tabs value={value} onChange={handleChange} aria-label="tabs" variant='scrollable' scrollButtons="auto">
              <Tab label="Picks" component={Link} to="/" style={{ color: 'white', fontSize: 15 }} />
              <Tab label="Leaderboard" component={Link} to="/leaderboard" style={{ color: 'white', fontSize: 15 }} />
              <Tab label="Rules" component={Link} to="/rules" style={{ color: 'white', fontSize: 15 }} />

            </Tabs>
          </Toolbar>
        </AppBar>

        <Routes>
          <Route path="/" element={<Picks />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/rules" element={<Rules />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
