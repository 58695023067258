import React from 'react';
import { Button, Card, CardContent, Grid, Typography, Avatar, Divider } from '@mui/material';

const PlayerCard = ({ playerData, onOver, onUnder, yardsType }) => {

    const handleOver = () => {
        onOver('over',playerData);
    };

    const handleUnder = () => {
        onUnder('under', playerData);
    };

    return (
        <Card >
            <CardContent>
                <Grid container >
                    {/* First Column */}
                    <Grid item xs={4} container direction="column" alignItems="center" alignContent="center" textAlign='center'>
                        <Avatar src={playerData.playerImage} alt={playerData.playerName} style={{ width: 100, height:100 }} />
                        <Typography variant="body1" component="div">
                            {playerData.playerName}
                        </Typography>
                        <Typography variant="h6" component="div">
                            {playerData.team}
                        </Typography>
                    </Grid>

                    {/* Second Column */}
                    <Grid item xs={4} container direction="column" alignItems="center" textAlign='center'> 
                        <Typography variant="body1" component="div">
                            {playerData.game}
                        </Typography>
                        <Divider  sx={{width: 100, height: 2, my: 1}}/>

                        <Typography variant="h6" component="div">
                            {playerData.yards} {yardsType} Yards
                        </Typography>
                    </Grid>

                    {/* Third Column */}
                    <Grid item xs={4} container direction="column" alignItems="center" spacing={2} marginTop={'5px'}  >
                        <Grid item >
                        <Button sx={{width:100, height: 50}}  variant={playerData.status === 'over' ? "contained" : "outlined"} color="primary" onClick={handleOver}>
                            Over
                        </Button>
                        </Grid>
                        <Grid item>
                        <Button sx={{width:100, height: 50}}  variant={playerData.status === 'under' ? "contained" : "outlined"} color="primary" onClick={handleUnder}>
                            Under
                        </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default PlayerCard;
