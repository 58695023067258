import React, { useState } from 'react';
import { Typography, Divider, Box, Button, Grid, Modal, TextField } from '@mui/material';
import PlayerCard from './PlayerCard';
import players from './output.json';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import ycLogo from './yc-logo.png'; // Adjust the path accordingly

function Picks() {
    const [yardsType, setYardsType] = useState('Rushing');
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [venmo, setVenmo] = useState('');
    const [data, setData] = useState(players);

    const handleModalClose = () => {

        setModalOpen(false);
    };

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleButtonClick = (type) => {
        setYardsType(type);
        console.log(`Selected Yards Type: ${type}`);
    };

    const handleConfirm = async () => {
        setLoading(true);
        console.log('Confirmed with:', { email, name, venmo });
        console.log('Selected Players:', selectedPlayers);
        let simplifiedPlayers = selectedPlayers.map((p) => {
            return {
                playerName: p.playerName,
                yards: p.yards,
                yardsType: p.yardsType,
                status: p.status
            }
        })

        let body = {
            email: email,
            name: name,
            venmo: venmo,
            picks: JSON.stringify(simplifiedPlayers)
        }
        let resp = await axios.post('https://xi13wdmr6f.execute-api.us-east-1.amazonaws.com/default/sendpicksemail', body)
        console.log(resp)
        setEmail('');
        setName('');
        setVenmo('');
        setSelectedPlayers([]);
        setData(players);
        setLoading(false);
        handleModalClose();

        //navigate to /leaderboard with react router
        navigate('/leaderboard');


    };

    const handleSumit = () => {
        console.log(selectedPlayers)
        setModalOpen(true)
    }

    const isConfirmEnabled = email && name && venmo;

    const isSubmitEnabled = selectedPlayers.length === 6;

    const handlePlayerClick = (type, player) => {
        //find the player in data that matches the player.playerName and update its status to type
        const updatedData = data[yardsType.toLowerCase()].map((p) => {
            if (p.playerId === player.playerId) {
                return p.status === type ? { ...p, status: null } : { ...p, status: type };
            }
            return p;
        });
        //update data
        setData({ ...data, [yardsType.toLowerCase()]: updatedData });
        //add player to selectedPlayers
        player = { ...player, status: type, yardsType: yardsType };
        //check if player is already in selectedPlayers
        const index = selectedPlayers.findIndex((p) => p.playerId === player.playerId);
        if (index !== -1) {
            //check if the player is already in selectedPlayers and has the same status
            if (selectedPlayers[index].status === type) {
                //remove the player from selectedPlayers
                setSelectedPlayers(selectedPlayers.filter((p) => p.playerId !== player.playerId));
                return;
            }

            //if player is already in selectedPlayers, update the status
            const updatedSelectedPlayers = selectedPlayers.map((p) => {
                if (p.playerId === player.playerId) {
                    return { ...p, status: type, yardsType: yardsType };
                }
                return p;
            });
            setSelectedPlayers(updatedSelectedPlayers);
            return;
        }
        else {
            setSelectedPlayers([...selectedPlayers, player]);
        }
    };

    return (
        <>
            <Typography variant="h4" align="center" sx={{ marginTop: 2 }}>
            Make 6 correct picks. Get $100 in your Venmo. That’s it.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', align: "center", justifyContent: 'center' }}>
                <Typography variant="h6" align="center">A </Typography>
                <img src={ycLogo} alt="YC Logo" style={{ width: '120px', height: 'auto', marginLeft: 5, marginRight: 5 }} />
                <Typography variant="h6">Experiment</Typography>
            </Box>
            <Divider sx={{ width: '100%', marginBottom: 2 }} />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 2, // Spacing between buttons
                }}
            >
                <Button
                    variant={yardsType === 'Rushing' ? 'contained' : 'outlined'}
                    onClick={() => handleButtonClick('Rushing')}
                >
                    Rushing Yards
                </Button>
                <Button
                    variant={yardsType === 'Passing' ? 'contained' : 'outlined'}
                    onClick={() => handleButtonClick('Passing')}
                >
                    Passing Yards
                </Button>
                <Button
                    variant={yardsType === 'Receiving' ? 'contained' : 'outlined'}
                    onClick={() => handleButtonClick('Receiving')}
                >
                    Receiving Yards
                </Button>
            </Box>
            <Divider sx={{ width: '100%', marginTop: 2, marginBottom: 2 }} />
            <Box sx={{ height: 'calc(100vh - 450px)', overflowY: 'auto', mb: 8 }}> {/* Adjust the height as needed */}
                <Grid container spacing={5} sx={{ padding: '10px' }}>
                    {data[yardsType.toLowerCase()].map((player, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4}>
                            <PlayerCard playerData={player} onOver={handlePlayerClick} onUnder={handlePlayerClick} yardsType={yardsType} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '1rem',
                    backgroundColor: 'background.default',
                    boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant="body1">You selected {selectedPlayers.length}/6 picks</Typography>
                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={selectedPlayers.length === 0}
                        onClick={() => {
                            setSelectedPlayers([]);
                            setData(players);
                        }}
                    >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" disabled={!isSubmitEnabled} onClick={() => handleSumit()}>
                        Submit
                    </Button>
                </Box>
                <Divider sx={{ width: '100%', marginTop: 1 }} />
                <Typography variant="body2" sx={{ marginTop: '0.5rem' }}>Sponsored by Alex and Noah</Typography>
            </Box>



            {/* Modal */}
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                {loading ? <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        padding: 10,


                    }}
                ><Typography variant="h6" component="div" gutterBottom>
                        Saving your picks...
                    </Typography>
                    <CircularProgress size={40} /></Box> :
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '80%',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            outline: 'none',
                        }}
                    >
                        <Typography variant="h6" component="h1" gutterBottom>
                            Your Picks
                        </Typography>
                        {selectedPlayers.map((player, index) => (
                            <Typography key={index} variant="body1" component="div" gutterBottom>
                                {player.playerName} - {player.yards}  {player.yardsType} -  {player.status}
                            </Typography>
                        ))}
                        <Divider sx={{ width: '100%', marginTop: 2, marginBottom: 2 }} />

                        <Typography id="modal-title" variant="h6" component="h2">
                            Enter Details
                        </Typography>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Venmo Account*"
                            value={venmo}
                            onChange={(e) => setVenmo(e.target.value)}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2, marginBottom: 2 }}>
                            <Typography variant="body1">*we need your venmo handle so we can pay you when you win</Typography>
                        </Box>


                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={!isConfirmEnabled}
                            onClick={handleConfirm}
                        //and loading state
                        >
                            Confirm
                        </Button>
                    </Box>}
            </Modal>
        </>
    );
}

export default Picks;
